// import React from 'react';
// import './privacy.css';
//  const Privacy=()=>{
//     return ( <>
//    <div>

//         <section className="first-sec">
//             <h1 className="title">This is the Privacy and Cookies Policy of FAZCON APPS .All Rights Reserved</h1>
//         </section>
//         <section className="second-sec">
//             <article className="para">
//                 <p>BY INSTALLING THE APPS ON YOUR MOBILE DEVICE, ENTERING INTO, CONNECTING TO, ACCESSING, AND/OR USING THE FAZCON APPS YOU
//                 AGREE TO THE TERMS AND CONDITIONS SET FORTH IN THIS PRIVACY POLICY. PLEASE NOTE: IF YOU OR, AS APPLICABLE, YOUR LEGAL
//                 GUARDIAN, DISAGREE TO ANY TERM PROVIDED HEREIN, YOU MAY NOT INSTALL, ACCESS, AND/OR USE THE APPS AND YOU ARE REQUESTED
//                 TO PROMPTLY ERASE THEM, AND ANY PART THEREOF, FROM YOUR MOBILE DEVICE.</p>

//                 <h3>Links to third-party websites and services:-</h3>
//                 <p>The APPS may contain links to other websites and online services, including third-party advertisements. If you choose to
//                 click through to one of these other websites or online services, please note that any information you may provide will
//                 be subject to the privacy policy and other terms and conditions of that websites or service, and not to this Privacy
//                 Policy. We do not share your data with third-party websites or services, and the fact that a link to such website or
//                 service does not appear in the APPS. You agree that our application will have no liability for any matters relating to a
//                 third-party website or service that you provide information to, including their collection and handling of that
//                 information.</p>

//               <h3>File Sharing App - Handling of User Info</h3>
//               <p>
//                 In our “File Sharing Manager” helps you share files for which you will need to allow the application to access files on you device.
//                 The application will ask you to enter your email address/phone number or signin with gmail, this info will be stored on our servers for registration/record.
//                 user's email or other primary account information or gmail id is kept on our servers securely for registration and to identify you and help you restore data from the cloud.
//                 AGREE AND CONTINUE
//                 By continuing, you agree to the File Sharing Manger’s Privacy Policy which describes how the data is handled.
//               </p>

//               <h3>Backup & Restore App - Handling of User Info/Primary Account info</h3>
//               <p>
//                 In our “Backup & Restore” App helps you backup your phone data i.e videos,images ,contacts for that you need to allow the application to access files on you device.
//                 The application will ask you to enter your email address/phone number or signin with gmail, this info will be stored on our servers for registration/record.
//                 user's email or other primary account information or gmail id is kept on our servers securely for registration and to identify you and help you restore data from the cloud.
//                 AGREE AND CONTINUE
//                 By continuing, you agree to the Backup and Restore’s Privacy Policy which describes how the User's data is handled.
//               </p>

//               <h3>Handling of User Data</h3>
//                   <p> All user data including media files (audio, video, images) and documents (including contact lists) are securely handled by Our Apps by employing encryption. Moreover, the files that the user backs up on our servers are completely private and are in no way publically visible or accessible.
//                   As per practice in vogue, the app’s personnel may need to access file contents in rare scenarios like:
//                   a.	Legal obligation
//                   b.	Ensuring satisfactory user experience (e.g. debugging, performance testing, search result verification, recommendations etc.)
//                   c.	Enforcing our Terms of Service and Acceptable Use Policy.
//                   Such file access is limited to a very small number of people. We ensure implementation of strict policy and access controls.</p>
//               <h3>Sharing of User Information.</h3>
//                   <p>Any information collected by Our Apps is only for improving user experience. All information collected is encrypted through industry standard mechanisms. Our Apps employs best practices to safeguard user information from unauthorized visibility/ access.
//                   In certain cases, Our Apps may disclose user information to 3rd parties for advertising purposes. Our Apps uses advertisement campaigns in a manner so as not to reveal personal information of the users while providing tailored advertisement to suit user requirements.
//                   In case of legal action, Our Apps may be obliged to disclose user information to third parties against some malicious acts on part of the user.
//                   Our Apps advises its users to take necessary precautions when sharing information with unknown 3rd parties. Please read the policies carefully before proceeding.</p>


//                 <h3>
//                     Compliance with laws and law enforcement:-
//                 </h3>
//                 <p>("We") reserves the right to share your information with third parties if we believe such action is necessary in order
//                 to:</p>
//                 <ul>
//                   <li>Conform to the requirements of the law or to comply with legal process served upon us</li>
//                   <li>Protect or defend our legal rights or property.</li>
//                   <li> Investigate, prevent or take action regarding illegal activities, suspected fraud, situations involving potential
//                     threats to the physical safety of any person or violations of the terms and conditions of using our Apps and Services. </li>
//                 </ul>

// <h3>Permissions Our Apps May Ask:-</h3>
// <p>Our apps may ask for following permissions but that will be for the sole purpose of providing core functionality. There will be no invasion of privacy or unethical use of resources or data.</p>
// <ul>
// <li>Camera: - Take photos and videos </li>
// <li>Storage modify or delete the contents of your USB storage</li>
// <li>read the contents of your USB storage  </li>
// <li>Photo media files</li>
// <li>Read phone state</li>
// <li>Internet</li>
// <li>Billing permission</li>
// </ul>
// <h3>
//     Security
// </h3>
// <p>
//     <span style={{fontweight: "bold"}}> FAZCON APPS </span> has implemented technology and policies to safeguard your privacy from unauthorized access and improper use.
//     FAZCON APPS has implemented technology and policies to safeguard your privacy from unauthorized access and improper use.
// </p>
// <h3>Changes to our Privacy Policy:-</h3>
// <p>This Privacy Policy may be updated for any reason e.g. legal, economic, public opinion etc. We reserve the right to update the policy and have no obligation to actively inform users. Users are welcome to revisit the page for updates to the policy.</p>
// <h3>Contact us</h3>
// <p>If you have any questions regarding privacy while using the apps, or have questions about our practices, please contact
// us via email at <span style={{fontweight: "bold"}}>info@fazconapps.com</span></p>
//             </article>
//         </section>
//     </div>
// </> )
// }

// export default Privacy;











import React from 'react';
import './privacy.css';

const Privacy = () => {
    return (
        <div className="privacy-container">
            <section className="first-sec">
                {/* <h1 className="title">This is the Privacy and Cookies Policy of FAZCON APPS. All Rights Reserved</h1> */}
                <h1 className="title">Privacy and Cookies Policy of FAZCON APPS.</h1>
            </section>
            <section className="second-sec">
                <article className="para">
                    <p>BY INSTALLING THE APPS ON YOUR MOBILE DEVICE, ENTERING INTO, CONNECTING TO, ACCESSING, AND/OR USING THE FAZCON APPS YOU
                        AGREE TO THE TERMS AND CONDITIONS SET FORTH IN THIS PRIVACY POLICY. PLEASE NOTE: IF YOU OR, AS APPLICABLE, YOUR LEGAL
                        GUARDIAN, DISAGREE TO ANY TERM PROVIDED HEREIN, YOU MAY NOT INSTALL, ACCESS, AND/OR USE THE APPS AND YOU ARE REQUESTED
                        TO PROMPTLY ERASE THEM, AND ANY PART THEREOF, FROM YOUR MOBILE DEVICE.</p>
                    <h3>Links to third-party websites and services:</h3>
                    <p>The APPS may contain links to other websites and online services, including third-party advertisements. If you choose to
                        click through to one of these other websites or online services, please note that any information you may provide will
                        be subject to the privacy policy and other terms and conditions of that websites or service, and not to this Privacy
                        Policy. We do not share your data with third-party websites or services, and the fact that a link to such website or
                        service does not appear in the APPS. You agree that our application will have no liability for any matters relating to a
                        third-party website or service that you provide information to, including their collection and handling of that
                        information.</p>
                    <h3>File Sharing App - Handling of User Info</h3>
                    <p>
                        In our “File Sharing Manager” helps you share files for which you will need to allow the application to access files on you device.
                        The application will ask you to enter your email address/phone number or sign in with gmail, this info will be stored on our servers for registration/record.
                        User's email or other primary account information or gmail id is kept on our servers securely for registration and to identify you and help you restore data from the cloud.
                        AGREE AND CONTINUE
                        By continuing, you agree to the File Sharing Manager’s Privacy Policy which describes how the data is handled.
                    </p>
                    <h3>Backup & Restore App - Handling of User Info/Primary Account info</h3>
                    <p>
                        In our “Backup & Restore” App helps you backup your phone data i.e videos, images, contacts for that you need to allow the application to access files on you device.
                        The application will ask you to enter your email address/phone number or sign in with gmail, this info will be stored on our servers for registration/record.
                        User's email or other primary account information or gmail id is kept on our servers securely for registration and to identify you and help you restore data from the cloud.
                        AGREE AND CONTINUE
                        By continuing, you agree to the Backup and Restore’s Privacy Policy which describes how the User's data is handled.
                    </p>
                    <h3>Handling of User Data</h3>
                    <p> All user data including media files (audio, video, images) and documents (including contact lists) are securely handled by Our Apps by employing encryption. Moreover, the files that the user backs up on our servers are completely private and are in no way publicly visible or accessible.
                        As per practice in vogue, the app’s personnel may need to access file contents in rare scenarios like:
                        a. Legal obligation
                        b. Ensuring satisfactory user experience (e.g. debugging, performance testing, search result verification, recommendations etc.)
                        c. Enforcing our Terms of Service and Acceptable Use Policy.
                        Such file access is limited to a very small number of people. We ensure implementation of strict policy and access controls.</p>
                    <h3>Sharing of User Information.</h3>
                    <p>Any information collected by Our Apps is only for improving user experience. All information collected is encrypted through industry-standard mechanisms. Our Apps employ best practices to safeguard user information from unauthorized visibility/ access.
                        In certain cases, Our Apps may disclose user information to 3rd parties for advertising purposes. Our Apps use advertisement campaigns in a manner so as not to reveal personal information of the users while providing tailored advertisement to suit user requirements.
                        In case of legal action, Our Apps may be obliged to disclose user information to third parties against some malicious acts on part of the user.
                        Our Apps advise its users to take necessary precautions when sharing information with unknown 3rd parties. Please read the policies carefully before proceeding.</p>
                    <h3>
                        Compliance with laws and law enforcement:
                    </h3>
                    <p>("We") reserve the right to share your information with third parties if we believe such action is necessary in order to:</p>
                    <ul style={{ fontFamily: '"Times New Roman", Times, serif', fontSize: '14px', color: 'black', fontWeight: 'normal' }}>
        <li style={{ fontSize: '14px', color: 'black', fontWeight: 'normal', textDecoration: 'none' }}>
          Conform to the requirements of the law or to comply with legal process served upon us
        </li>
        <li style={{ fontSize: '14px', color: 'black', fontWeight: 'normal', textDecoration: 'none' }}>
          Protect or defend our legal rights or property.
        </li>
        <li style={{ fontSize: '14px', color: 'black', fontWeight: 'normal', textDecoration: 'none' }}>
          Investigate, prevent or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person or violations of the terms and conditions of using our Apps and Services.
        </li>
      </ul>
                    <h3>Permissions Our Apps May Ask:</h3>
                    <p>Our apps may ask for the following permissions but that will be for the sole purpose of providing core functionality. There will be no invasion of privacy or unethical use of resources or data.</p>
                    <ul style={{ fontFamily: '"Times New Roman", Times, serif', fontSize: '14px', color: 'black', fontWeight: 'normal' }}>
        <li style={{ fontSize: '14px', color: 'black', fontWeight: 'normal', textDecoration: 'none' }}>Camera: - Take photos and videos</li>
        <li style={{ fontSize: '14px', color: 'black', fontWeight: 'normal', textDecoration: 'none' }}>Storage modify or delete the contents of your USB storage</li>
        <li style={{ fontSize: '14px', color: 'black', fontWeight: 'normal', textDecoration: 'none' }}>Read the contents of your USB storage</li>
        <li style={{ fontSize: '14px', color: 'black', fontWeight: 'normal', textDecoration: 'none' }}>Photo media files</li>
        <li style={{ fontSize: '14px', color: 'black', fontWeight: 'normal', textDecoration: 'none' }}>Read phone state</li>
        <li style={{ fontSize: '14px', color: 'black', fontWeight: 'normal', textDecoration: 'none'}}>Internet</li>
        <li style={{ fontSize: '14px', color: 'black', fontWeight: 'normal', textDecoration: 'none'}}>Billing permission</li>

        </ul>







                    
                    {/* <ul>
                        <li>Camera: - Take photos and videos </li>
                        <li>Storage modify or delete the contents of your USB storage</li>
                        <li>read the contents of your USB storage  </li>
                        <li>Photo media files</li>
                        <li>Read phone state</li>
                        <li>Internet</li>
                        <li>Billing permission</li>
                    </ul> */}
                    <h3>How to Delete Data permanently:</h3>
                    <p>To permanently delete a Cloud Storage account:</p>
                    <ol style={{ fontFamily: '"Times New Roman", Times, serif', fontSize: '14px', color: 'black', fontWeight: 'normal' }}>
        <li style={{ fontSize: '14px', color: 'black', fontWeight: 'normal', textDecoration: 'none' }}>
           1. Open this link <a href="https://fazconapps.com/datadelete">https://fazconapps.com/datadelete</a>
        </li>
        <li style={{ fontSize: '14px', color: 'black', fontWeight: 'normal', textDecoration: 'none' }}>
          2. Login using Google or Phone Number
        </li>
        <li style={{ fontSize: '14px', color: 'black', fontWeight: 'normal', textDecoration: 'none' }}>
          3. Click "Delete account" at the top of the page
        </li>
      </ol>
                    <p>You can also delete a Cloud Backup account from the Cloud Storage app. To do this:</p>
                    <ol style={{ fontFamily: '"Times New Roman", Times, serif', fontSize: '14px', color: 'black', fontWeight: 'normal' }}>
        <li style={{ fontSize: '14px', color: 'black', fontWeight: 'normal', textDecoration: 'none' }}>1. Open the Cloud Backup mobile app</li>
        <li style={{ fontSize: '14px', color: 'black', fontWeight: 'normal', textDecoration: 'none' }}>2. Tap Settings (Settings icon)</li>
        <li style={{ fontSize: '14px', color: 'black', fontWeight: 'normal', textDecoration: 'none' }}>3. Tap Delete account</li>
      </ol>

                    <h3>Security</h3>
                    <p>
                        <span style={{ fontWeight: "bold" }}> FAZCON APPS </span> has implemented technology and policies to safeguard your privacy from unauthorized access and improper use.
                    </p>
                    <h3>Changes to our Privacy Policy:</h3>
                    <p>This Privacy Policy may be updated for any reason e.g. legal, economic, public opinion etc. We reserve the right to update the policy and have no obligation to actively inform users. Users are welcome to revisit the page for updates to the policy.</p>
                    <h3>Contact us</h3>
                    <p>If you have any questions regarding privacy while using the apps, or have questions about our practices, please contact
                        us via email at <span style={{ fontWeight: "bold" }}>info@fazconapps.com</span></p>
                </article>
            </section>
        </div>
    );
}

export default Privacy;

