// import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import { auth, signOut } from '../firebase';

// const ConfirmDeletion = () => {
//   const navigate = useNavigate();

//   const handleSignOut = async () => {
//     try {
//       await signOut(auth);
//       navigate('/');  // Navigate to the root page after signing out
//     } catch (error) {
//       console.error('Sign out failed:', error);
//     }
//   };

//   return (
//     <div style={{ textAlign: 'center', padding: '20px', color: 'white' }}>
//       <h2>Are you sure you want to delete your Subway Surfers account?</h2>
//       <p>You have been logged in with Google.</p>
//       <button style={{ padding: '10px 20px', fontSize: '16px', margin: '10px', backgroundColor: '#f44336', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
//         YES, PLEASE DELETE MY ACCOUNT
//       </button>
//       <p>Want to cancel the account deletion?</p>
//       <button
//         onClick={handleSignOut}
//         style={{ padding: '10px 20px', fontSize: '16px', margin: '10px', backgroundColor: '#4285f4', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
//       >
//         Sign out and cancel
//       </button>
//     </div>
//   );
// };

// export default ConfirmDeletion;


// src/components/ConfirmDeletion.js

// src/components/ConfirmDeletion.js
// import React from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { auth, signOut } from '../firebase';
// import axios from 'axios';

// const ConfirmDeletion = () => {
// //   const navigate = useNavigate();
// //   const location = useLocation();
// //   const userEmail = location.state.userEmail; // Get userEmail from location state
// const [userEmail, setUserEmail] = useState('');
// const navigate = useNavigate();

// useEffect(() => {
//   const auth = getAuth();
//   const unsubscribe = onAuthStateChanged(auth, (user) => {
//     if (user) {
//       setUserEmail(user.email);
//     } else {
//       // User is not signed in, redirect to home page or sign-in page
//       navigate('/');
//     }
//   });
//   return () => unsubscribe();
// }, [navigate]);



//   const handleDeleteAccount = async () => {
//     try {
//       const response = await axios.get('https://dn5n3r0k1j.execute-api.us-east-1.amazonaws.com/dev/userID', {
//         params: { key: userEmail }
//       });
//       if (response.data.response.statusCode === 200) {
//         alert('Account deletion successful.');
//       } else {
//         alert('Failed to delete account.');
//       }
//     } catch (error) {
//       console.error('Error deleting account:', error);
//       alert('An error occurred while deleting the account.');
//     }
//   };

//   const handleCancel = async () => {
//     try {
//       await signOut(auth);
//       navigate('/');
//     } catch (error) {
//       console.error('Sign out failed:', error);
//     }
//   };

//   return (
//     <div className="confirm-deletion">
//       <h2>Are you sure you want to delete your Subway Surfers account?</h2>
//       <p>You have been logged in with Google.</p>
//       <button onClick={handleDeleteAccount}>YES, PLEASE DELETE MY ACCOUNT</button>
//       <button onClick={handleCancel}>Sign out and cancel</button>
//     </div>
//   );
// };

// export default ConfirmDeletion;



// // src/components/ConfirmDeletion.js
// // src/components/ConfirmDeletion.js
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { getAuth } from '../firebase';
// import { signOut } from "firebase/auth";
// import { onAuthStateChanged } from "firebase/auth";


// const ConfirmDeletion = () => {
//   const [userEmail, setUserEmail] = useState('');
//   const navigate = useNavigate();

//   useEffect(() => {
//     const auth = getAuth();
//     const unsubscribe = onAuthStateChanged(auth, (user) => {
//       if (user) {
//         setUserEmail(user.email);
//       } else {
//         // User is not signed in, redirect to home page or sign-in page
//         navigate('/');
//       }
//     });
//     return () => unsubscribe();
//   }, [navigate]);

//   const handleDelete = async () => {
//     try {
//       // Make API call to delete user data
//       const response = await axios.get(`https://dn5n3r0k1j.execute-api.us-east-1.amazonaws.com/dev/userID?key=${userEmail}`);
//       if (response.data && response.data.response && response.data.response.message === 'Data Deleted Successfully') {
//         // Delete user from Firebase
//         // await deleteUser();
//         // Redirect to home page
//         console.log("Data Deleted Successfully")
//         navigate('/');
//       } else {
//         console.error('Failed to delete user data:', response.data.response.message);
//       }
//     } catch (error) {
//       console.error('Error deleting user data:', error);
//     }
//   };

//   const handleSignOut = async () => {
//     try {
//       const auth = getAuth();
//       await signOut(auth);

//       console.log("SignOut Successfully");
//       navigate('/');
//     } catch (error) {
//       console.error('Error signing out:', error);
//     }
//   };

//   // Render confirmation deletion UI
// //   return (
// //     <div>
// //       <h2>Are you sure you want to delete your Subway Surfers account?</h2>
// //       <p>You have been logged in with:</p>
// //       <p>{userEmail}</p>
// //       <button onClick={handleDelete}>YES, PLEASE DELETE MY ACCOUNT</button>
// //       <button onClick={handleSignOut}>Sign out and cancel</button>
// //     </div>
// //   );
// // };


// return (
//         <div style={{ textAlign: 'center', padding: '20px', color: 'white' }}>
//           <h2>Are you sure you want to delete your Subway Surfers account?</h2>
//           <p>You have been logged in with Google.</p>
//           <button 
//           onClick={handleDelete}
//           style={{ padding: '10px 20px', fontSize: '16px', margin: '10px', backgroundColor: '#f44336', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
//             YES, PLEASE DELETE MY ACCOUNT
//           </button>
//           <p>Want to cancel the account deletion?</p>
//           <button
//             onClick={handleSignOut}
//             style={{ padding: '10px 20px', fontSize: '16px', margin: '10px', backgroundColor: '#4285f4', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
//           >
//             Sign out and cancel
//           </button>
//         </div>
//       );
//     };

// export default ConfirmDeletion;





















//Working Code
// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';

// const ConfirmDeletion = () => {
//   const [userEmail, setUserEmail] = useState('');
//   const [userPhoneNumber, setUserPhoneNumber] = useState('');
//   const [successMessage, setSuccessMessage] = useState('');
//   const navigate = useNavigate();

// //   useEffect(() => {
// //     const auth = getAuth();
// //     const unsubscribe = onAuthStateChanged(auth, (user) => {
// //       if (user) {
// //         setUserEmail(user.email);
// //       } else {
// //         navigate('/');
// //       }
// //     });
// //     return () => unsubscribe();
// //   }, [navigate]);


//   useEffect(() => {
//     const auth = getAuth();
//     const unsubscribe = onAuthStateChanged(auth, (user) => {
//       if (user) {
//         if (user.email) {
//           setUserEmail(user.email);
//         } else if (user.phoneNumber) {
//           setUserPhoneNumber(user.phoneNumber);
//         }
//       } else {
//         navigate('/');
//       }
//     });
//     return () => unsubscribe();
//   }, [navigate]);

//   const handleDelete = async () => {
//     try {
//         const key = userEmail || userPhoneNumber;

//         console.log("Key is ",key);
        
//         const response = await axios.get(`https://dn5n3r0k1j.execute-api.us-east-1.amazonaws.com/dev/userID?key=${key}`);
       
//     //   const response = await axios.get(`https://dn5n3r0k1j.execute-api.us-east-1.amazonaws.com/dev/userID?key=${userEmail}`);
//       if (response.data && response.data.response && response.data.response.message === 'Data Deleted Successfully') {
//         console.error('Data Deleted Successfully');
 
//         setSuccessMessage('Data Deleted Successfully');
//         setTimeout(async () => {
//           const auth = getAuth();
//           await signOut(auth);
//           navigate('/');
//         }, 2000); // Delay of 2 seconds before signing out and navigating
//       } else {
//         console.error('Failed to delete user data:', response.data.response.message);
//       }
//     } catch (error) {
//       console.error('Error deleting user data:', error);
//     }
//   };

//   const handleSignOut = async () => {
//     try {
//       const auth = getAuth();
//       await signOut(auth);
//       navigate('/');
//     } catch (error) {
//       console.error('Error signing out:', error);
//     }
//   };

//   return (
//     <div style={{ textAlign: 'center', padding: '20px', color: 'white' }}>
//       <h2>Are you sure you want to delete your Subway Surfers account?</h2>
//       <p>You have been logged in with Google.</p>
//       {successMessage ? (
//         <div>
//           <p>{successMessage}</p>
//         </div>
//       ) : (
//         <div>
//           <button
//             onClick={handleDelete}
//             style={{ padding: '10px 20px', fontSize: '16px', margin: '10px', backgroundColor: '#f44336', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
//           >
//             YES, PLEASE DELETE MY ACCOUNT
//           </button>
//           <p>Want to cancel the account deletion?</p>
//           <button
//             onClick={handleSignOut}
//             style={{ padding: '10px 20px', fontSize: '16px', margin: '10px', backgroundColor: '#4285f4', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
//           >
//             Sign out and cancel
//           </button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ConfirmDeletion;


























import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import './ConfirmDeletion.css';  // Assuming you have a CSS file for styles

const ConfirmDeletion = () => {
  const [userKey, setUserKey] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Use either email or phone number as the key
        setUserKey(user.email || user.phoneNumber || '');
      } else {
        navigate('/');
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  const handleDelete = async () => {
    try {
      if (!userKey) {
        console.error('No user key found');
        return;
      }

      const response = await axios.post(
        'https://uvbjbjunkc.execute-api.us-east-1.amazonaws.com/default/backupapp_deleteaccount',
        { username: userKey },
        { headers: { 'Content-Type': 'application/json' } }
      );

    //   if (response.data && response.data.response && response.data.response.message === 'Data Deleted Successfully') {
        console.log("Response is "+response.data);
        if (response.data && response.data.responseStatus === 'SUCCESS') {
          console.log("Success Found");

        setSuccessMessage('Data Deleted Successfully');
        setTimeout(async () => {
          const auth = getAuth();
          await signOut(auth);
          navigate('/');
        }, 2000); // Delay of 2 seconds before signing out and navigating
      } else {
        console.error('Failed to delete user data:', response.data.response.message);
      }
    } catch (error) {
      console.error('Error deleting user data:', error);
    }
  };

  const handleSignOut = async () => {
    try {
      const auth = getAuth();
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (

    <div className="confirm-deletion-container">

        <img src="/image3.jpg" alt="Background" className="fullscreen-image" />
        <img src="/imagetest2.png" alt="Top Left Image" className="top-left-image" />

        <div className="diagonal-shape"></div>

        <div id="overlay-container"> 
        <div className="overlay">


        
            <h2>Are you sure you want to delete your Backup account?</h2>
            <p>You have been logged in</p>
            {successMessage ? (
        <       div>
          <p>{successMessage}</p>
        </div>
      ) : (
        <div>
          <button
            onClick={handleDelete}
            className="delete-button"
          >
            YES, PLEASE DELETE MY ACCOUNT
          </button>
          <p>Want to cancel the account deletion?</p>
          <button
            onClick={handleSignOut}
            className="cancel-button"
          >
            Sign out and cancel
          </button>
        </div>
        )}




        </div>


        </div>
    </div>
  );
};

export default ConfirmDeletion;
