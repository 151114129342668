import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut, signInWithPhoneNumber, RecaptchaVerifier, onAuthStateChanged } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyB1HeEqulrZqY0hE_L5Ikj53Msf_t3akKw",
  authDomain: "fastbackupapp.firebaseapp.com",
  projectId: "fastbackupapp",
  storageBucket: "fastbackupapp.appspot.com",
  messagingSenderId: "751556258053",
  appId: "1:751556258053:web:8dd520d4b4ae273686af6c",
  measurementId: "G-JE4JP4NWT9"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, provider, signInWithPopup, signOut, signInWithPhoneNumber, RecaptchaVerifier, getAuth, onAuthStateChanged };