// import React from 'react'
// // import {BrowserRouter as Router,Route,Switch} from 'react-router-dom';
// import App from './App';
// import Privacy from './privacy';
// import Text from './app-ads.txt'
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// function RouteSteup() {
//     return (
//         <Router>
//             <Switch>
//        <Route exact path='/'>
//        <App/>
//        </Route>
//        <Route exact path="/app-ads.txt" component={Text} />
//        <Route path='/privacy'>
//        <Privacy/>
//       </Route>

// </Switch>
//         </Router>
//     )
// }

// export default RouteSteup;


import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import App from './App';
import Privacy from './privacy';
import Text from './app-ads.txt';
import DataDelete from './components/datadelete'; // Importing DataDelete from datadelete.js
import PhoneNumberSignIn from './components/PhoneNumberSignIn';
import ConfirmDeletion from './components/ConfirmDeletion';

function RouteSetup() {
    return (
        <Router>
            <Routes>
                <Route path='/' element={<App />} />
                <Route path="/app-ads.txt" element={<Text />} />
                <Route path='/privacy' element={<Privacy />} />
                <Route path='/datadelete' element={<DataDelete />} /> {/* Adding a new route for HomePage */}
                <Route path="/phone-signin" element={<PhoneNumberSignIn />} />
                <Route path="/confirm-deletion" element={<ConfirmDeletion />} />
            </Routes>
        </Router>
    );
}

export default RouteSetup;


