









import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, signInWithPhoneNumber, RecaptchaVerifier } from '../firebase'; // Import necessary Firebase functions
import './PhoneNumberSignIn.css';



const PhoneNumberSignIn = ({ setUserPhoneNumber }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [verificationCode, setVerificationCode] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    setupRecaptcha();
  }, []);

  const setupRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth,'recaptcha-container', {
      'size': 'invisible',
      'callback': (response) => {
        console.log("Recaptcha verified");
      },
      'expired-callback': () => {
        setMessage('reCAPTCHA expired, please try again.');
      }
    }, auth);
  };

  const handleSignIn = async (e) => {
    if (e) e.preventDefault();

    setMessage('Sending verification code...');
    try {
      const appVerifier = window.recaptchaVerifier;
      const confirmation = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
      setConfirmationResult(confirmation);
      setMessage('Verification code sent!');
    } catch (error) {
      console.error('Phone sign-in failed:', error);
      setMessage(`Failed to send verification code: ${error.message}`);
    }
  };

  // const handleVerificationCode = async (e) => {
  //   e.preventDefault();
  //   setMessage('Verifying code...');
  //   try {
  //     const result = await confirmationResult.confirm(verificationCode);
  //     console.log('Phone verification successful!');
  //     setMessage('Phone verification successful!');
  //     setUserPhoneNumber(result.user.phoneNumber);
  //     navigate('/confirm-deletion'); // Navigate to the ConfirmDeletion page
  //   } catch (error) {
  //     console.error('Phone verification failed:', error);
  //     setMessage('Invalid verification code. Please try again.');
  //   }
  // };


  const handleVerificationCode = async (e) => {
    e.preventDefault();
    setMessage('Verifying code...');
    try {
      const result = await confirmationResult.confirm(verificationCode);
      console.log('Phone verification successful!');
      setMessage('Phone verification successful!');
      navigate('/confirm-deletion'); // Navigate to the ConfirmDeletion page
    } catch (error) {
      console.error('Phone verification failed:', error);
      setMessage('Invalid verification code. Please try again.');
    }
  };

  // return (


    
  //   <div style={{ textAlign: 'center', padding: '20px', color: 'white' }}>
  //     <h2 style={{ fontSize: '28px', marginBottom: '20px' }}>Delete my Subway Surfers account</h2>
  //     <p style={{ fontSize: '18px', marginBottom: '10px' }}>
  //       What will be deleted?
  //       <br />
  //       Your account linked with the profile and all associated data. Any local progress stored on your device will not be deleted – only data stored remotely.
  //       <br />
  //       Please sign in to the provider that was used to sign up for your Subway Surfers account:
  //     </p>
  //     <form onSubmit={handleSignIn}>
  //       <input
  //         type="tel"
  //         value={phoneNumber}
  //         onChange={(e) => setPhoneNumber(e.target.value)}
  //         placeholder="Enter your phone number"
  //         style={{ padding: '10px', marginBottom: '10px' }}
  //       />
  //       <button id="sign-in-button" type="submit" style={{ padding: '10px 20px', fontSize: '16px' }}>
  //         Send Verification Code
  //       </button>
  //     </form>
  //     {confirmationResult && (
  //       <form onSubmit={handleVerificationCode}>
  //         <input
  //           type="text"
  //           value={verificationCode}
  //           onChange={(e) => setVerificationCode(e.target.value)}
  //           placeholder="Enter verification code"
  //           style={{ padding: '10px', marginTop: '10px', marginBottom: '10px' }}
  //         />
  //         <button type="submit" style={{ padding: '10px 20px', fontSize: '16px' }}>
  //           Verify Code
  //         </button>
  //       </form>
  //     )}
  //     <div id="recaptcha-container"></div>
  //     {message && <p>{message}</p>}
  //   </div>
  // );



  return (
    <div className="confirm-deletion-container">
      <img src="/image3.jpg" alt="Background" className="fullscreen-image" />
      <img src="/imagetest2.png" alt="Top Left Image" className="top-left-image" />
      <div className="diagonal-shape"></div> 
      <div id="overlay-container">
        <div className="overlay">
            <h2 className="header">Delete my Backup account</h2>
            <p className="paragraph">
              What will be deleted?
              <br />
              Your account linked with the profile and all associated data.
              <br />
              Please sign in to the provider that was used to sign up for your Cloud Storage account:
            </p>
            <form onSubmit={handleSignIn}>
              <input
                type="tel"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder="Enter your phone number"
                className="input"
              />
              <button id="signin-button" type="submit" className="button">
                Send Verification Code
              </button>
            </form>
            {confirmationResult && (
              <form onSubmit={handleVerificationCode}>
                <input
                  type="text"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  placeholder="Enter verification code"
                  className="verification-input"
                />
                <button type="submit" className="button">
                  Verify Code
                </button>
              </form>
            )}
            <div id="recaptcha-container"></div>
            {message && <p>{message}</p>}
        </div>
      </div>
    </div>
  );
};



export default PhoneNumberSignIn;

