// import React from 'react';
// import { Link } from 'react-router-dom';
// import GoogleSignIn from './GoogleSignIn';
// import './datadelete.css';

// const DataDelete = () => (
//     <div className="home-page">
//         <img src="/image3.jpg" alt="Background" className="fullscreen-image" />
//         {/* <img src="/imagetest2.png" alt="Top Left Image" className="top-left-image" /> */}
//         <img src="/about.jpg" alt="Top Left Image" className="top-left-image" />

//         <div className="diagonal-shape"></div>

//         <div className="overlay">
//             <h2>Delete my Backup account</h2>
//             <p><strong>What will be deleted?</strong></p>
//             <p>Your account linked with the profile and all associated data. Any local progress stored on your device will not be deleted – only data stored remotely.</p>
//             <p>Please sign in to the provider that was used to sign up for your Fazcon Account</p>
//             <div className="buttons-container">
//                 <GoogleSignIn />
//                 <div className="centered">
//                     <Link to="/phone-signin">
//                         <button className="signin-button">Phone Number Sign-In</button>
//                     </Link>
//                 </div>
//                 {/* <FacebookSignIn /> */}
//             </div>
//         </div>
//     </div>
// );

// export default DataDelete;

import React from 'react';
import { Link } from 'react-router-dom';
import GoogleSignIn from './GoogleSignIn';
import './datadelete.css';

const DataDelete = () => (
    <div className="home-page">
        <img src="/image3.jpg" alt="Background" className="fullscreen-image" />
        <img src="/imagetest2.png" alt="Top Left Image" className="top-left-image" />

        <div className="diagonal-shape"></div>

        <div id="overlay-container"> {/* Add this container with the ID overlay-container */}
            <div className="overlay">
                <h2>Delete my Backup account</h2>
                <p><strong>What will be deleted?</strong></p>
                {/* <p>Your account linked with the profile and all associated data. Any local progress stored on your device will not be deleted – only data stored remotely.</p> */}
                <p>Your account linked with the profile and all associated data.</p>
 
                <p>Please sign in to the provider that was used to sign up for your Fazcon Account</p>
                <div className="buttons-container">
                    <GoogleSignIn />


                    <div className="centered">
                        <Link to="/phone-signin">
                            <button className="signin-button">Phone Number Sign-In</button>
                        </Link>
                    </div>


                    
                    {/* <FacebookSignIn /> */}
                </div>


                <div className="delete-steps-container">



                <p>You can also delete a Backup account from the Cloud Storage app. To do this:</p>
                <ol className="delete-steps">
                    <li>1. Open the Cloud Backup mobile app.</li>
                    <li>2. Tap Settings (Settings icon).</li>
                    <li>3. Tap Delete account.</li>
                </ol>

                </div>
            </div>
        </div>
    </div>
);

export default DataDelete;
