import React from "react";
import { Spring } from "react-spring/renderprops";


const Header = ({data}) => {

  return (
    <Spring from={{ marginTop:100 }} to={{marginTop:0 }} config={{ duration: 2000 }}>
      {(props) => (
        <div style={props}>
          <header id="header">
            <div className="intro">
              <div className="overlay">
                <div className="container">
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2 intro-text">
                      <Spring
                        from={{ marginLeft: 300 }}
                        to={{ marginLeft: 0 }}
                        config={{ duration: 2000 }}
                      >
                        {(props) => (
                          <h1 style={props}>
                            {data ? data.title : "Loading"}
                            <span></span>
                          </h1>
                        )}
                      </Spring>
                      <Spring
                        from={{ marginLeft: -300 }}
                        to={{ marginLeft: 0 }}
                        config={{ duration: 2000 }}
                      >
                        {(props) => (
                          <p style={props}>
                            {data ? data.paragraph : "Loading"}
                          </p>
                        )}
                      </Spring>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </div>
      )}
    </Spring>
  );
};

export default Header;
