import React from 'react'
import Header from './components/header';
import About from './components/about';
import Features from './components/features';
import Navigation from './components/navigation';
import Contact from './components/contact';
import JsonData from './data/data.json'


const App=()=>{
  return (<>
    <div>
 <Navigation/> 
<Header data={JsonData.Header}/>
<About data={JsonData.About} />
<Features data = {JsonData.Features} />
<Contact data = {JsonData.Contact}/>
      </div>
  </>)
}

export default App;

