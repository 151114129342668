// // src/components/GoogleSignIn.js
// import React, { useState, useEffect } from 'react';
// import { auth, provider, signInWithPopup, signOut } from '../firebase';
// import { onAuthStateChanged } from "firebase/auth";

// const GoogleSignIn = () => {
//   const [user, setUser] = useState(null);

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
//       if (currentUser) {
//         console.log('User email:', currentUser.email);
//       }
//       setUser(currentUser);
//     });
//     return () => unsubscribe();
//   }, []);

//   const handleLogin = async () => {
//     try {
//       const result = await signInWithPopup(auth, provider);
//       console.log('User email:', result.user.email);
//     } catch (error) {
//       console.error('Login failed:', error);
//     }
//   };

//   const handleLogout = async () => {
//     try {
//       await signOut(auth);
//     } catch (error) {
//       console.error('Sign out failed:', error);
//     }
//   };

//   return (
//     <div className="google-signin">
//       {user ? (
//         <>
//           <p>Welcome, {user.displayName} ({user.email})!</p>
//           <button onClick={handleLogout}>Sign out</button>
//         </>
//       ) : (
//         <button onClick={handleLogin}>Sign in with Google</button>
//       )}
//     </div>
//   );


// };

// export default GoogleSignIn;




import React, { useState, useEffect } from 'react';
import { auth, provider, signInWithPopup } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';



const GoogleSignIn = ({ setUserDetails }) => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        console.log('User email:', currentUser.email);
        // setUserDetails(currentUser);
        navigate('/confirm-deletion');  // Navigate to the confirm deletion page
      }
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, [setUserDetails, navigate]);

  const handleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      console.log('User email:', result.user.email);
    //   setUserDetails(result.user);
      // navigate('/confirm-deletion');  // Navigate to the confirm deletion page
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  return (
    <div className="google-signin">
      {user ? (
        <p>Welcome, {user.displayName} ({user.email})!</p>
      ) : (
        <button onClick={handleLogin}>Sign in with Google</button>
      )}
    </div>
  );
};

export default GoogleSignIn;



// src/components/GoogleSignIn.js
// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { auth, provider, signInWithPopup, signOut } from '../firebase';
// import { onAuthStateChanged } from 'firebase/auth';

// const GoogleSignIn = () => {
//   const [user, setUser] = useState(null);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
//       if (currentUser) {
//         setUser(currentUser);
//         navigate('/confirm-deletion'); // Navigate to confirmation page on sign-in
//       } else {
//         setUser(null);
//       }
//     });

//     return () => unsubscribe();
//   }, [navigate]);

//   const handleLogin = async () => {
//     try {
//       await signInWithPopup(auth, provider);
//     } catch (error) {
//       console.error('Login failed:', error);
//     }
//   };

//   const handleLogout = async () => {
//     try {
//       await signOut(auth);
//       setUser(null);
//     } catch (error) {
//       console.error('Sign out failed:', error);
//     }
//   };

//   return (
//     <div className="google-signin">
//       {user ? (
//         <>
//           <p>Welcome, {user.displayName} ({user.email})!</p>
//           <button onClick={handleLogout}>Sign out</button>
//         </>
//       ) : (
//         <button onClick={handleLogin}>Sign in with Google</button>
//       )}
//     </div>
//   );
// };

// export default GoogleSignIn;
