import React from "react";

const Navigation=()=>{
  return (<>
 <nav id="menu" className="navbar navbar-default navbar-fixed-top">
         <div className="container">
           <div className="navbar-header">
           <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              {" "}
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>
            <a className="navbar-brand page-scroll" href="#page-top">
             Fazcon | <span style={{font:'6px',color:'brown'}}>App studio</span>
            </a>{" "}
          </div>

          <div
            className="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
          >
            <ul className="nav navbar-nav navbar-right">
              <li>
                <a href='#header' className="page-scroll">
                 Home
                </a>
              </li>
              <li>
                <a href='#about' className="page-scroll">
                  About
                </a>
              </li>
              <li>
                <a href='#features' className="page-scroll">
                  Services
                </a>
              </li>  
               <li>
                <a href='#contact' className="page-scroll">
                  Contact
                </a>
              </li> 
            </ul>
          </div>
        </div>
      </nav>
</>)
}

export default Navigation;
