import React from 'react'
import { Spring } from 'react-spring/renderprops'
import image from '../components/about.jpg'


const about=({data})=>{
  
      return (
        <Spring from={{marginTop:100}}
 to={{marginTop:0}}
 config={{duration:5000}}>
   {
     props=>(
       <div style={props}>
        <div id="about">
        <div className="container">
          <div className="row">
            <img className="col-xs-12 col-md-6" src={image} alt="" />
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <h2>About Us</h2>
                <p>{data ? data.paragraph : 'loading...'}</p>
                <h3>Why Choose Us?</h3>
                <div className="list-style">
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {data ?data.Why.map((d, i) => <li  key={`${d}-${i}`}>{d}</li>) : 'loading'}
                    </ul>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                    {data ? data.Why2.map((d, i) => <li  key={`${d}-${i}`}> {d}</li>) : 'loading'}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
     )}
     </Spring>
     
    )
}
export default about
