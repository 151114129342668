import React from "react";
import { Spring } from 'react-spring/renderprops';


const Features=({data})=>{


    return (
 <Spring from={{marginTop:100}}
 to={{marginTop:0}}
 config={{duration:5000}}>
   {
     props=>(
       <div style={props}>
      <div id="features" className="text-center">
        <div className="container">
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2>Services</h2>
          </div>
          <div className="row">
            {data
              ? data.map((d,i) => (
                  <div  key={`${d.title}-${i}`} className="col-xs-6 col-md-3">
                    {" "}
                    <i className={d.icon} aria-hidden="true"></i>
                    <h3>{d.title}</h3>
                    <p>{d.text}</p>
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
      </div>
     )}
     </Spring>
    );
}
export default Features;
